<template>
  <v-card>
    <v-card-title class="text-h5" primary-title>
      <h6 class="text-h6">
        {{ id ? $trans("edit_tag") : $trans("add_tag") }}
      </h6>
    </v-card-title>
    <v-divider />
    <v-form ref="form_tag" @submit.prevent="handleSave">
      <v-card-text class="mt-2">
        <v-container fluid>
          <v-row>
            <v-col class="pa-0">
              <v-text-field
                v-model="name"
                :rules="[rules.required, rules.maxChars(30)]"
                :label="$trans('tag')"
                outlined
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn color="light" text :disabled="isLoading" @click="$emit('close')">
          {{ $trans("cancel") }}
        </v-btn>
        <v-btn
          :disabled="isLoading || !name"
          color="accent_light"
          text
          type="submit"
        >
          {{ $trans("save") }}
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import {
  maxChars,
  required,
} from "@/lib/calendesk-js-library/forms/validators";
import {
  errorNotification,
  successNotification,
} from "@/lib/calendesk-js-library/tools/notification";
import { pushEvent } from "@/lib/calendesk-js-library/tools/helpers";
import sharedActions from "@/lib/calendesk-js-library/mixins/sharedActions";

export default {
  name: "TagDialog",
  mixins: [sharedActions],
  data() {
    return {
      isLoading: false,
      name: null,
      id: null,
      tagCopy: null,
      rules: {
        required,
        maxChars,
      },
    };
  },
  watch: {
    "getConfirmDialog.data": function () {
      this.fillForm();
    },
  },
  created() {
    this.fillForm();
  },
  methods: {
    ...mapActions({
      createTag: "tag/create",
      updateTag: "tag/update",
      refreshTags: "tag/refreshTags",
      fetchAllTags: "tag/fetchAll",
    }),
    fillForm() {
      if (this.getConfirmDialog.data) {
        const tag = this.$helpers.cloneObject(this.getConfirmDialog.data);

        this.tagCopy = tag;
        this.name = tag.name;
        this.id = tag.id;
      }
    },
    handleSave() {
      if (this.$refs.form_tag.validate()) {
        this.isLoading = true;
        this.$emit("loading", true);
        this.getConfirmDialog.data ? this.update() : this.add();
      }
    },
    async add() {
      try {
        pushEvent("createTag");

        await this.createTag(this.name);
        successNotification("added_tag");
      } catch (error) {
        errorNotification(null, error);
      } finally {
        this.isLoading = false;
        this.$emit("loading", false);
        this.$emit("close");
        this.fetchAllTags();
        this.refreshTags();
      }
    },
    async update() {
      try {
        pushEvent("updateTag");

        await this.updateTag({ name: this.name, id: this.id });

        this.fetchAllTags();
        this.refreshTags();
      } catch (error) {
        errorNotification(null, error);
      } finally {
        this.isLoading = false;
        this.$emit("loading", false);
        this.$emit("close");
      }
    },
  },
};
</script>
